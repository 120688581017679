<template>
  <div class="list-transaction">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col
        md="12"
        lg="12"
        sm="12"
      >
        <KTCodePreview v-bind:title="isShow('TRANSACTION_INSERT') ? ' ' : ''">
          <!-- ACTION BAR -->
          <template
            v-slot:toolbar
            v-if="isShow('TRANSACTION_INSERT')"
          >
            <b-row>
              <b-dropdown
                size="sm"
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem"
                    class="fas fa-cog"
                  ></i>
                  <span class="font-weight-bolder">Thao tác</span>
                </template>
                <b-dropdown-item @click="excelModal">
                  <span>
                    <i
                      style="font-size: 1rem"
                      class="far fa-file-excel"
                    ></i>
                    &nbsp; Xuất Excel
                  </span>
                </b-dropdown-item>
              </b-dropdown>
              <div
                class="ml-3"
                v-if="mode != 'cashbook'"
              >
                <b-dropdown
                  size="sm"
                  id="dropdown-right"
                  right
                  variant="primary"
                >
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-add-1"
                    ></i>
                    <span class="font-weight-bolder">Thêm mới</span>
                  </template>
                  <b-dropdown-item v-if="mode == 'cash'">
                    <router-link to="/accounting/transactions/cash/add/new">
                      <span style="color: #3f4254; width: 100%">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-add-1"
                        ></i>
                        &nbsp; Thêm phiếu thu chi
                      </span>
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="mode == 'bank'">
                    <router-link to="/accounting/transactions/bank/add/new">
                      <span style="color: #3f4254; width: 100%">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-add-1"
                        ></i>
                        &nbsp; Nộp tiền | Rút tiền
                      </span>
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="mode == 'all'">
                    <router-link to="/accounting/transactions/transfer/add/new">
                      <span style="color: #3f4254; width: 100%">
                        <i
                          style="font-size: 1rem"
                          class="fas fa-exchange-alt"
                        ></i>
                        &nbsp; Chuyển quỹ
                      </span>
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-row>
          </template>
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5">
              <b-col>
                <b-input
                  placeholder="Mã ID"
                  v-model="apiParams.id"
                  append-icon="search"
                  single-line
                  hide-details
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col>
                <b-input-group>
                  <div class="date-style">
                    <b-input-group>
                      <date-picker
                        placeholder="Từ"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.fromDate"
                      ></date-picker>
                    </b-input-group>
                    <span class="ml-1 mr-1"></span>
                    <b-input-group>
                      <date-picker
                        placeholder="Đến"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.toDate"
                      ></date-picker>
                    </b-input-group>
                  </div>
                </b-input-group>
              </b-col>
              <b-col>
                <b-form>
                  <b-form-select
                    v-model="apiParams.type"
                    :options="getTransactiontypes"
                    size="sm"
                    class="select-style"
                    @change="changeTypeChilds"
                  ></b-form-select>
                </b-form>
              </b-col>
              <b-col v-show="['cash', 'cashbook'].includes(mode)">
                <b-form>
                  <b-form-select
                    v-model="apiParams.typeChild"
                    :options="typeChilds"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    class="select-style"
                    @change="changeTypeParents"
                  ></b-form-select>
                </b-form>
              </b-col>

              <b-col>
                <b-form>
                  <b-form-select
                    v-model="apiParams.contactType"
                    :options="getContactTypes"
                    size="sm"
                    class="select-style"
                  ></b-form-select>
                </b-form>
              </b-col>
              <b-col v-show="!['cash', 'cashbook'].includes(mode)"></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-input
                  placeholder="Mã tài khoản"
                  v-model="apiParams.accountantCode"
                  append-icon="search"
                  single-line
                  hide-details
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col>
                <b-input
                  placeholder="Chứng từ"
                  v-model="apiParams.docId"
                  append-icon="search"
                  single-line
                  hide-details
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col>
                <b-input
                  placeholder="Số điện thoại KH"
                  v-model="apiParams.customerPhone"
                  append-icon="search"
                  single-line
                  hide-details
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col>
                <b-form>
                  <BDropdownCustom
                    v-model="searchStock"
                    :options="filterdOptionsStore"
                    :placeholder="'cửa hàng'"
                    :searchable="true"
                    :matchKey="['name']"
                    :limit="5"
                  />
                </b-form>
              </b-col>
              <b-col>
                <b-input
                  placeholder="Nội dung phiếu"
                  v-model="apiParams.search"
                  append-icon="search"
                  single-line
                  hide-details
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-input-group>
                  <div class="date-style">
                    <b-input-group>
                      <b-input
                        type="number"
                        placeholder="Từ số tiền"
                        v-model="apiParams.priceFrom"
                        size="sm"
                        autocomplete="off"
                        v-on:keyup.enter="onFilter()"
                      />
                    </b-input-group>
                    <span class="ml-1 mr-1"></span>
                    <b-input-group>
                      <b-input
                        type="number"
                        placeholder="Đến số tiền"
                        v-model="apiParams.priceTo"
                        autocomplete="off"
                        size="sm"
                        v-on:keyup.enter="onFilter()"
                      />
                    </b-input-group>
                  </div>
                </b-input-group>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col cols="2">
                <b-button
                  id="btnFilter"
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="
                    () => {
                      apiParams.page = 1;
                      fetchMainData();
                    }
                  "
                >
                  Lọc
                </b-button>
              </b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col
                md="12"
                lg="12"
                sm="12"
              >
                <b-table
                  :items="mainList.dataset"
                  :fields="getTableFields"
                  :busy="onLoadingList"
                  class="table-bordered table-hover"
                >
                  <template v-slot:table-busy>
                    <vcl-table
                      :speed="5"
                      :animate="true"
                      :columns="10"
                    />
                  </template>
                  <!-- Format id column -->
                  <template v-slot:cell(id)="row">
                    <div class="text-left">
                      <span
                        style="display: block"
                        class="text-primary d-flex"
                      >
                        {{ row.item.id }}
                        <i
                          v-if="!row.item.isAuto"
                          class="fa fa-user ml-1 mt-1 text-dark"
                          style="font-size: 0.9rem"
                          v-b-tooltip
                          :title="formatMode(row.item.isAuto)"
                        />
                      </span>

                      <span
                        v-if="mode != 'all'"
                        style="display: block; font-size: 0.8rem"
                      >
                        {{ formatDate(row.item.createdAt) }}
                      </span>

                      <p
                        style="font-size: 12px"
                        class="mt-1"
                      >
                        {{ row.item.employeeName }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:cell(type)="row">
                    <div class="d-flex justify-content-left align-items-center">
                      {{ formatType(row.item.type) }}
                    </div>
                    <span v-if="row.item.typeChildName">({{ row.item.typeChildName }})</span>
                    <p
                      class="mt-2"
                      style="font-size: 13px; font-weight: 600"
                    >
                      {{ row.item.storeName }}
                    </p>
                  </template>
                  <!-- Format accountant column -->
                  <template v-slot:cell(accountantName)="row">
                    <b
                      v-if="mode != 'cashbook'"
                      style="display: block"
                    >{{ row.item.accountantCode }}</b>
                    <span style="display: block">{{ row.item.accountantName }}</span>
                  </template>
                  <!-- Format accountant column -->
                  <template v-slot:cell(accountantRefName)="row">
                    <b style="display: block">{{ row.item.accountantRefCode }}</b>
                    <span style="display: block">{{ row.item.accountantRefName }}</span>
                  </template>
                  <!-- Format contact column -->
                  <template v-slot:cell(reconciliationDocId)="row">
                    <span v-if="row.item.reconciliationDocType !== null && row.item.reconciliationDocType !== '0'">
                      {{ docType(row.item.reconciliationDocType) }}
                      <br />
                      <a
                        class="font-weight-bold"
                        v-bind:href="getLinkDoc(row.item)"
                        target="_blank"
                      >
                        {{ row.item.reconciliationDocId }}
                      </a>
                    </span>
                  </template>
                  <!-- Format amount in column -->
                  <template v-slot:cell(amountIn)="row">
                    <span
                      v-if="isShowAmount(row.item.type, 'in') || mode == 'all'"
                      style="display: block"
                    >
                      {{ convertPrice(row.item.amount) }}
                    </span>
                  </template>
                  <!-- Format amount out column -->
                  <template v-slot:cell(amountOut)="row">
                    <span
                      v-if="isShowAmount(row.item.type, 'out')"
                      style="display: block"
                    >
                      {{ convertPrice(row.item.amount) }}
                    </span>
                  </template>

                  <template v-slot:cell(attachments)="row">
                    <i
                      class="fa fa-download text-primary hover-cursor"
                      aria-hidden="true"
                      @click="openFileModal(row.item)"
                      v-if="!row.item.isAuto"
                    ></i>
                  </template>
                  <!-- Format action -->
                  <template v-slot:cell(actions)="row">
                    <div
                      class="d-flex justify-content-center"
                      v-if="!row.item.isAuto && isShow(['TRANSACTION_UPDATE', 'TRANSACTION_DELETE'])"
                    >
                      <b-dropdown
                        size="sm"
                        id="dropdown-left"
                        right
                        v-if="isShow(['TRANSACTION_UPDATE', 'TRANSACTION_DELETE'])"
                        no-caret
                      >
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <b-dropdown-item
                          v-if="isShow(['TRANSACTION_UPDATE'])"
                          @click="editItem(row.item)"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="flaticon2-pen"
                            ></i>
                            &nbsp; Chỉnh sửa
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="isShow(['TRANSACTION_DELETE'])"
                          @click="showDeleteAlert(row.item)"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem; color: #d33"
                              class="flaticon2-rubbish-bin-delete-button"
                            ></i>
                            &nbsp; Xóa
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                  <!-- SUM AMOUNT ROW -->
                  <template slot="bottom-row">
                    <td
                      v-bind:colspan="mode == 'all' ? 5 : mode != 'cashbook' ? 6 : 7"
                      class="text-right font-weight-bolder"
                      style="font-weight: 600,color: '#181c32'"
                    >
                      <span>Tổng</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumInAmount) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span v-if="mode != 'all'">{{ convertPrice(sumOutAmount) }}</span>
                    </td>
                    <td></td>
                    <td v-if="mode == 'all'"></td>
                    <td v-if="mode != 'cashbook'"></td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col
                lg="3"
                md="3"
                sm="12"
              >
                <p
                  class="mt-3 text-dark"
                  style="font-weight: 500"
                >
                  Tổng số phiếu:
                  {{ convertPrice(mainList.total) }}
                </p>
              </b-col>
              <b-col
                lg="9"
                md="9"
                sm="12"
              >
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
    <b-modal
      ref="excel-modal"
      hide-footer
      title="Xuất excel"
      id="excel-modal"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-1"
                  v-model="selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                  <b-form-radio value="row">Chọn cột</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-2"
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="current">Trang hiện tại</b-form-radio>
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div
              class="col-6"
              v-show="showHeaderExcel"
            >
              <b-form-group>
                <template #label>
                  Cột:
                  <br />
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="items"
                    aria-controls="items"
                    @change="toggleAll"
                  >
                    {{ allSelected ? 'Bỏ chọn tất cả' : 'Tất cả' }}
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group
                  id="items"
                  v-model="selectedHeader"
                  :options="optionsHeader"
                  name="items"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
            <div v-if="selectedListExcel === 'all'">
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <label for="input-name">Từ ngày:</label>
                    <date-picker
                      placeholder="Từ ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="exportExcelModal.fromDate"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="input-name">Đến ngày:</label>
                    <date-picker
                      placeholder="Tới ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="exportExcelModal.toDate"
                    ></date-picker>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <b-button
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
          ref="kt_excel"
        >
          <v-icon
            small
            v-show="!btnDisable"
            class="text-white"
            v-b-tooltip
          >mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <b-modal
      ref="file-modal"
      size="lg"
      hide-footer
      title="Chỉnh sửa hình ảnh"
      id="file-modal"
    >
      <b-row>
        <b-col cols="12">
          <UploadFile
            :id="fileUpload.transactionId"
            :entity="'transactions'"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import {
  TRANSACTION_TYPE,
  CONTACT_TYPE as CONTACT_TYPE_LIST,
  BASE_URL,
} from '@/utils/constants';
import { createFile, getFileName } from '@/utils/file-download';
import { mapGetters } from 'vuex';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { checkPermissions } from '@/utils/saveDataToLocal';
import axios from 'axios';
import { getSubtractDate, getAddDate } from '@/utils/date';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

const MIN_RANGE_DATE = getSubtractDate(7);
const MAX_RANGE_DATE = getAddDate();
import UploadFile from '@/view/modules/upload/Upload';

import { cloneDeep, map, find, assign } from 'lodash';
import { removeAccents, convertPrice } from '@/utils/common';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  // Data of view.
  data() {
    return {
      mode: 'cash',
      title: 'Danh sách thu chi',
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        id: '',
        page: 1,
        pageSize: 10,
        search: '',
        mode: 'cash',
        storeId: [],
        type: '',
        typeChild: '',
        contactType: '',
        accountantCode: '',
        fromDate: MIN_RANGE_DATE,
        toDate: MAX_RANGE_DATE,
        docId: null,
        customerPhone: null,
        ...this.searchProps,
      },
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      optionsStore: [],
      filterdOptionsStore: [],
      searchStock: [],
      selectedRowExcel: 'all',
      selectedListExcel: 'current',
      showHeaderExcel: false,
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
      typeChilds: [
        {
          id: '',
          name: 'Chi tiết phiếu',
        },
      ],
      typeChildsOld: [],
      btnDisable: false,
      exportExcelModal: {
        fromDate: null,
        toDate: null,
      },
      fileUpload: {
        transactionId: null,
      },
    };
  },
  props: {
    searchProps: Object,
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    UploadFile,
    BDropdownCustom,
  },
  mounted() {
    this.loadBreadcum();
    this.fetchStoreByUser();
    if (this.searchProps) {
      this.apiParams = Object.assign(this.apiParams, this.searchProps);
    }
  },
  created() {
    this.np;
    this.loadTitle(true);
    this.fetchTransactionCategory();
  },
  watch: {
    selectedHeader(newVal) {
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.optionsHeader.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
    mode: function (val) {
      this.mode = val;
      const storeId = this.apiParams.storeId || [];
      this.apiParams = {
        page: 1,
        pageSize: 10,
        search: '',
        mode: val,
        storeId: storeId,
        type: '',
        typeChild: '',
        contactType: '',
        accountantCode: '',
        fromDate: MIN_RANGE_DATE,
        toDate: MAX_RANGE_DATE,
      };
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    fetchMainData: async function (page) {
      this.apiParams.page = page ? page : 1;
      this.apiParams.mode = this.$route.params.mode;

      if (this.searchStock.length === 0) {
        this.apiParams.storeId = [];
      }

      this.apiParams.storeId = this.searchStock.map(({ code }) => code);

      this.onLoadingList = true;
      ApiService.query(cmdUrl.TransactionUrl.root, {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            this.makeToastFailure('Lỗi!');
          } else {
            const message = err.response.data.message;
            this.makeToastFailure(message);
          }
          this.onLoadingList = false;
          this.$nprogress.done();
        });
    },
    formatDate: function (dateVal) {
      return moment(dateVal)
        .format('DD/MM/yyyy HH:mm:ss')
        .replace('00:00:00', '');
    },
    formatType: function (type) {
      const transType = _.find(TRANSACTION_TYPE, ['value', type]);
      return transType ? transType.text : 'Không rõ';
    },
    formatMode: function (isAuto) {
      return isAuto ? 'Hoạch toán tự động' : 'Hoạch toán tự tạo';
    },
    docType: function (type) {
      if (type === null || type === 0) return '';
      let result = '';
      if (type === '1' || type === '6') {
        result = 'Phiếu XNK: ';
      } else if (type === '2') {
        result = 'Đơn hàng: ';
      } else if (type === '3' || type === '4') {
        result = 'Phí quẹt thẻ: ';
      } else if (type === '5') {
        result = 'Phí quẹt thẻ đơn hàng: ';
      } else if (type === '7') {
        result = 'Phiếu mượn máy: ';
      }
      return result;
    },
    isShowAmount: function (type, mode = 'in') {
      const arrCheck = _.filter(TRANSACTION_TYPE, ['mode', mode]);
      return _.map(arrCheck, 'value').indexOf(type) > -1;
    },
    loadTitle: function (isFirst) {
      const mode = this.$route.params.mode;
      if (isFirst) this.mode = mode;
      switch (mode) {
        case 'cash':
          this.title = 'Danh sách thu chi tiền mặt';
          break;
        case 'bank':
          this.title = 'Danh sách báo nợ, báo có ngân hàng';
          break;
        case 'cashbook':
          this.apiParams.id = this.$route.query.id || null;
          this.title = 'Tổng hợp thu chi';
          break;
        case 'all':
          this.title = 'Danh sách bút toán';
          break;
        default:
          break;
      }
    },

    loadBreadcum: function () {
      this.loadTitle();
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: this.title },
      ]);
    },
    editItem: function (item) {
      const mode = ['1', '2'].indexOf(item.type) > -1 ? 'cash' : 'bank';
      this.$router.push({
        name: 'transaction',
        params: { mode: mode, action: 'edit', id: item.id },
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu thu chi!',
        text: 'Bạn có chắc muốn xóa?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item.id);
        }
      });
    },
    deleteItem: async function (id) {
      const apiUrl = cmdUrl.TransactionUrl.byId.replace('{id}', id);
      ApiService.delete(apiUrl)
        .then((response) => {
          const { message } = response.data;
          this.makeToastSuccess(message);
          this.fetchMainData();
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    getLinkDoc(item) {
      if (item.reconciliationDocType === '1') {
        if (_.startsWith(item.reconciliationDocId, 'NK')) {
          return `#/stocks/update-stock?id=${item.reconciliationDocId}`;
        }
        return `#/bills/update-bill?id=${item.reconciliationDocId}`;
      } else if (
        item.reconciliationDocType === '2' ||
        item.reconciliationDocType === '5'
      ) {
        return `#/orders/update-order?id=${item.reconciliationDocId}`;
      } else if (item.reconciliationDocType === '3') {
        return `#/bills/update-bill?id=${item.reconciliationDocId}`;
      } else if (item.reconciliationDocType === '4') {
        return `#/wholesaleInvoices/update-wholesale-invoice?id=${item.reconciliationDocId}`;
      } else if (item.reconciliationDocType === '6') {
        return `#/inventory-check/upsert?code=${item.reconciliationDocId}`;
      }
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    excelModal: async function () {
      this.optionsHeader = [
        {
          text: 'ID',
          value: 'id',
          disabled: true,
          selected: true,
          default: true,
        },
        {
          text: 'Ngày tạo',
          value: 'date',
          disabled: false,
          selected: true,
        },
        {
          text: 'Loại phiếu',
          value: 'typeName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Cửa hàng',
          value: 'storeName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Chi tiết loại phiếu',
          value: 'typeChildName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Mã tài khoản',
          value: 'accountantId',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tên Tài khoản',
          value: 'accountantName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Mã TK đối ứng',
          value: 'accountantRefCode',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tên TK đối ứng',
          value: 'accountantRefName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Mã đối tượng',
          value: 'contactCode',
          disabled: false,
          selected: true,
        },
        {
          text: 'Tên đối tượng',
          value: 'contactNameCus',
          disabled: false,
          selected: true,
        },
        {
          text: 'Loại Chứng từ',
          value: 'reconciliationDocName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Mã Chứng từ',
          value: 'reconciliationDocId',
          disabled: false,
          selected: true,
        },
        {
          text: 'Thu/Chi',
          value: 'amount',
          disabled: false,
          selected: true,
          customs: [
            {
              text: 'Thu',
              value: 'amountInCome',
              disabled: false,
              selected: true,
            },
            {
              text: 'Chi',
              value: 'amountExpense',
              disabled: false,
              selected: true,
            },
          ],
        },
        {
          text: 'Người tạo',
          value: 'createdByName',
          disabled: false,
          selected: true,
        },
        {
          text: 'Đường dẫn',
          value: 'attachments',
          disabled: false,
          selected: true,
        },
      ];
      this.optionsHeaderDefault = this.optionsHeader.filter((x) => x.default);
      this.selectedHeader = this.optionsHeader
        .filter((x) => x.selected)
        .map((x) => x.value);

      const current = moment();
      // if fromDate && toDate exist in filter, use for excel.
      if (this.apiParams.fromDate && this.apiParams.toDate) {
        this.exportExcelModal.fromDate = this.apiParams.fromDate;
        this.exportExcelModal.toDate = this.apiParams.toDate;
      } else {
        this.exportExcelModal.toDate = current.format('DD/MM/YYYY');
        this.exportExcelModal.fromDate = current
          .clone()
          .subtract(32, 'days')
          .format('DD/MM/YYYY');
      }
      this.$refs['excel-modal'].show();
    },
    calcDiffDate(fromDate, toDate, format = 'DD/MM/YYYY') {
      return moment(toDate, format).diff(moment(fromDate, format), 'days');
    },
    reportClick: async function () {
      if (this.selectedListExcel === 'all') {
        const excelDiffDate = this.calcDiffDate(
          this.exportExcelModal.fromDate,
          this.exportExcelModal.toDate,
        );

        if (excelDiffDate > 30) {
          return this.makeToastFailure(
            'Chỉ hỗ trợ xuất excel trong vòng 1 tháng',
          );
        }
      }

      const submitButton = this.$refs['kt_excel'];
      submitButton.classList.add(
        'spinner',
        'spinner-light',
        'spinner-left',
        'spinner-sm',
      );
      this.btnDisable = true;
      const customsChids = this.optionsHeader.filter((x) => x.customs);
      let selectedHeader = [...this.selectedHeader];
      if (customsChids.length > 0) {
        customsChids.forEach((element) => {
          let index = selectedHeader.findIndex((x) => x === element.value);
          if (index > -1) {
            let childs = element.customs.map((x) => x.value);
            selectedHeader.splice(index, 1);
            selectedHeader = selectedHeader.concat(childs);
          }
        });
      }

      const payload = {
        headers: selectedHeader,
      };

      if (this.selectedRowExcel !== 'row') {
        let selectAll = this.optionsHeader.map((x) => x.value);

        if (customsChids.length > 0) {
          customsChids.forEach((element) => {
            let index = selectAll.findIndex((x) => x === element.value);
            if (index > -1) {
              let childs = element.customs.map((x) => x.value);
              selectAll.splice(index, 1);
              selectAll = selectAll.concat(childs);
            }
          });
        }
        payload.headers = selectAll;
      }

      const { page, pageSize, ...rest } = this.apiParams;
      const params = { ...rest };

      if (this.selectedListExcel !== 'all') {
        params.page = page;
        params.pageSize = pageSize;
      } else {
        Object.assign(params, {
          fromDate: this.exportExcelModal.fromDate,
          toDate: this.exportExcelModal.toDate,
        });
      }
      params.mode = this.$route.params.mode;

      if (this.searchStock.length === 0) {
        params.storeId = [];
      }

      payload['searchParams'] = params;
      await this.exportExcel(payload);
      this.btnDisable = false;
      submitButton.classList.remove(
        'spinner',
        'spinner-light',
        'spinner-right',
      );
    },
    exportExcel(data) {
      const url = `${BASE_URL}transactions/export-excel`;
      axios({
        url: url,
        method: 'POST',
        data: data,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
      })
        .then((response) => {
          const fileName = getFileName(response);
          createFile(response.data, fileName);
        })
        .catch(() => {
          this.makeToastFailure('Xuất Excel không thành công');
        });
    },
    showHeader() {
      if (this.selectedRowExcel === 'row') {
        this.showHeaderExcel = true;
      } else {
        this.showHeaderExcel = false;
      }
    },
    toggleAll(checked) {
      this.selectedHeader = checked
        ? this.optionsHeader.map((x) => x.value)
        : this.optionsHeaderDefault.map((x) => x.value);
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.apiParams.storeId = [stores[0].id.toString()];
            let option = {
              code: stores[0].id,
              name: stores[0].shortName,
              checked: true,
              $isDisabled: true,
            };
            this.searchStock.push(option);
            this.optionsStore = [option];
          } else {
            stores.forEach((element) => {
              if (data.data.viewAll) {
                let option = {
                  code: element.id,
                  name: element.name,
                  shortName: element.shortName,
                  checked: false,
                  $isDisabled: false,
                };
                this.optionsStore.push(option);
              } else {
                let option = {
                  code: element.id,
                  name: element.shortName,
                  shortName: element.shortName,
                  checked: true,
                  $isDisabled: true,
                };
                this.searchStock.push(option);
                this.optionsStore.push(option);
                this.apiParams.storeId.push(option.code);
              }
            });
          }
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
        }
        this.fetchMainData();
      });
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name === option.name,
      );
      this.optionsStore[index].checked = true;

      this.apiParams.storeId.push(option.code);
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name === option.name,
      );
      this.optionsStore[index].checked = false;
      this.apiParams.storeId = this.apiParams.storeId.filter(
        (x) => x !== option.code,
      );
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onInputStoreChange(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, (obj) => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }
      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },

    onFilter() {
      this.$route.query.page = 1;
      this.changeURL();
      this.fetchMainData();
    },
    changeURL() {
      this.$router.push({
        name: 'list-transaction',
      });
    },
    fetchTransactionCategory: function () {
      const apiUrl = cmdUrl.TransactionCategory.root;
      ApiService.query(apiUrl, {}).then((response) => {
        const data = response.data.data || null;
        if (data) {
          this.typeChildsOld = data.list_caterogy;
          let list = data.list_caterogy.filter((x) => x.level !== 0);
          this.typeChilds = [...this.typeChilds, ...list];
        }
      });
    },
    changeTypeParents(id) {
      if (id === '') {
        return;
      }
      let items = this.getParentTypes(id);
      if (items.length > 0) this.apiParams.type = items[items.length - 1].id;
    },
    changeTypeChilds(id) {
      if (id === '') {
        this.apiParams.typeChild = '';
        let list = this.typeChildsOld.filter((x) => x.level !== 0);
        this.typeChilds = [this.typeChilds[0], ...list];
        return;
      }
      let items = this.getChildTypes(id);
      this.typeChilds = [this.typeChilds[0], ...items];
      this.apiParams.typeChild = '';
    },
    getParentTypes(typeId, items = []) {
      let item = this.typeChildsOld.find((x) => x.id === typeId);
      if (item) {
        items.push(item);
        if (item.parentId) this.getParentTypes(item.parentId, items);
      }
      return items;
    },
    getChildTypes(typeId, items = []) {
      let childs = this.typeChildsOld.filter((x) => x.parentId == typeId);
      if (childs.length > 0) {
        for (const child of childs) {
          items.push(child);
          this.getChildTypes(child.id, items);
        }
      }
      return items;
    },
    openFileModal(item) {
      this.fileUpload.transactionId = item.id;
      this.$refs['file-modal'].show();
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    sumInAmount() {
      const outItems = _.filter(this.mainList.dataset, (item) => {
        return this.isShowAmount(item.type, 'in');
      });
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item.amount);
        },
        0,
      );
    },
    sumOutAmount() {
      const outItems = _.filter(this.mainList.dataset, (item) => {
        return this.isShowAmount(item.type, 'out');
      });
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item.amount);
        },
        0,
      );
    },

    getTableFields() {
      const mode = this.$route.params.mode;
      let tableFields = [];
      switch (mode) {
        case 'cashbook':
          tableFields = [
            {
              key: 'id',
              label: 'ID/Ngày',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '8%',
              },
              tdClass: 'text-left',
            },
            {
              key: 'type',
              label: 'Loại',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '20%',
              },
              tdStyle: { width: '20%' },
              tdClass: 'text-left',
            },
            {
              key: 'accountantId',
              label: 'Mã tài khoản',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '10%',
              },
            },
            {
              key: 'accountantName',
              label: 'Tên tài khoản',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '15%',
              },
            },
            {
              key: 'type',
              label: 'Loại phiếu',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '20%',
              },
              tdClass: 'text-left',
            },
            {
              key: 'contactName',
              label: 'Đối tượng',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '10%',
              },
              tdClass: 'break-line',
            },
            {
              key: 'reconciliationDocId',
              label: 'Chứng từ',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '8%',
              },
            },
            {
              key: 'amountIn',
              label: 'Thu',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '10%',
              },
              tdClass: 'text-right',
            },
            {
              key: 'amountOut',
              label: 'Chi',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '10%',
              },
              tdClass: 'text-right',
            },
            {
              key: 'attachments',
              label: 'Tệp',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '5%',
              },
              tdClass: 'text-center',
            },
            {
              key: 'note',
              label: 'Ghi chú',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '20%',
              },
              tdClass: 'break-line',
            },
          ];
          break;
        case 'all':
          tableFields = [
            {
              key: 'id',
              label: 'ID/Ngày',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '8%',
              },
              tdClass: 'text-left',
            },
            {
              key: 'type',
              label: 'Loại phiếu',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '20%',
              },
              tdStyle: { width: '20%' },
              tdClass: 'text-left',
            },
            {
              key: 'contactName',
              label: 'Đối tượng',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '15%',
              },
              tdClass: 'break-line',
            },
            {
              key: 'reconciliationDocId',
              label: 'Chứng từ',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '8%',
              },
            },
            {
              key: 'amountIn',
              label: 'Số tiền',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '10%',
              },
              tdClass: 'text-right',
            },
            {
              key: 'accountantId',
              label: 'Nợ',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '10%',
              },
              tdClass: 'text-center',
            },
            {
              key: 'accountantRefId',
              label: 'Có',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '10%',
              },
              tdClass: 'text-center',
            },
            {
              key: 'note',
              label: 'Ghi chú',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: 'calc(18% -50px)',
              },
              tdClass: 'break-line',
            },
            {
              key: 'actions',
              label: '',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '50px',
              },
            },
          ];
          break;
        default:
          tableFields = [
            {
              key: 'id',
              label: 'ID/Ngày',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '8%',
              },
              tdClass: 'text-left',
            },
            {
              key: 'type',
              label: 'Loại',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '14%',
              },
              tdStyle: { width: '14%' },
              tdClass: 'text-left',
            },
            {
              key: 'accountantName',
              label: 'Tài khoản',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '12%',
              },
            },
            {
              key: 'accountantRefName',
              label: 'TK đối ứng',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '12%',
              },
            },
            {
              key: 'contactName',
              label: 'Đối tượng',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '12%',
              },
              tdClass: 'break-line',
            },
            {
              key: 'reconciliationDocId',
              label: 'Chứng từ',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '8%',
              },
            },
            {
              key: 'amountIn',
              label: 'Thu',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '8%',
              },
              tdClass: 'text-right',
            },
            {
              key: 'amountOut',
              label: 'Chi',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
              },
              tdClass: 'text-right',
            },
            {
              key: 'attachments',
              label: 'Tệp',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '5%',
              },
              tdClass: 'text-center',
            },
            {
              key: 'note',
              label: 'Ghi chú',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '20%',
              },
              tdClass: 'break-line',
            },
            {
              key: 'actions',
              label: '',
              sortable: false,
              thStyle: {
                ...thStyleCommon,
                width: '50px',
              },
            },
          ];
          break;
      }
      return tableFields;
    },

    getTransactiontypes() {
      const mode = this.$route.params.mode;
      let listTypes = [
        {
          value: '',
          text: 'Tất cả loại phiếu',
        },
      ];
      switch (mode) {
        case 'cashbook':
          listTypes.push(..._.filter(TRANSACTION_TYPE, ['parent', mode]));
          break;
        case 'all':
          listTypes.push(...TRANSACTION_TYPE);
          break;
        default:
          listTypes.push(..._.filter(TRANSACTION_TYPE, ['group', mode]));
          break;
      }
      return listTypes;
    },

    getContactTypes() {
      let listTypes = [
        {
          value: '',
          text: 'Tất cả đối tượng',
        },
      ];
      listTypes.push(...CONTACT_TYPE_LIST);
      return listTypes;
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;

    next();
  },
};
</script>

<style lang="scss">
.list-transaction {
  .hover-cursor {
    cursor: pointer;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }
  tbody {
    font-size: 12px;
  }
  .break-line {
    white-space: break-spaces;
  }

  .date-style {
    display: flex;
    align-items: center;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
